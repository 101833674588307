<script setup lang="ts">
import type { TableColumnType } from 'ant-design-vue'
import UserSelectModal from '../components/user-select-modal.vue'
import { queryLoginRecords } from '@/api/user'
import { LoginLogMap } from '@/constant/user'

const selectUserModalVisible = ref(false)
const columns = [
  { title: '用户名', dataIndex: 'loginName' },
  { title: '操作', dataIndex: 'operation', customRender({ record }) {
    return LoginLogMap.get(record.operation)
  } },
  { title: '操作时间', dataIndex: 'createdAt', customRender({ record }) {
    return useDateFormat(record.createdAt, 'YYYY-MM-DD HH:mm:ss').value
  } },
] as TableColumnType[]

const { state, onPageChange, onSearch } = usePageFetch({ apiFun: queryLoginRecords, columns, needPaging: true })
function onSelectedUser(userId: number) {
  onSearch({ userId })
}
</script>

<template>
  <page-container>
    <content-header title="登录日志" />
    <flex-content>
      <table-layout :need-header="true">
        <template #headerRight>
          <a-space size="middle">
            <a-button type="primary" @click="() => selectUserModalVisible = true">
              选择用户
            </a-button>
            <a-button @click="onSearch">
              重置
            </a-button>
          </a-space>
        </template>
        <template #content>
          <base-table v-bind="state" @page-change="onPageChange" />
        </template>
      </table-layout>
    </flex-content>
    <UserSelectModal v-model:open="selectUserModalVisible" @confirm="onSelectedUser" />
  </page-container>
</template>
